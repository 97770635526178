import { Button, FormControl, IconButton, LinearProgress, MenuItem, Modal, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FC, useEffect, useState } from 'react'
import { request } from '../../../utils/fetcher'
import ScheduleIcon from '@mui/icons-material/Schedule'
import CircleIcon from '@mui/icons-material/Circle'

const DeviceModal: FC<{ device: any; handleClose: () => void }> = ({ device, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [qrCode, setQRCode] = useState('')
  const [time, setTime] = useState(0)
  const [device_fila, setDeviceFila] = useState<string | number>('')
  let repeat = 0

  useEffect(() => {
    setQRCode('')
    setDeviceFila('')
  }, [device])

  useEffect(() => {
    if (time > 0) setTimeout(() => setTime(time - 1), 1000)
    else setQRCode('')
  }, [time])

  const checkDeviceStatus = async (deviceId: number) => {
    const data = await request(`company/get-devices`, {}, {}, { method: 'GET' })

    const updatedDevice = data.devices.filter((d: any) => d.value === deviceId)

    if (updatedDevice.length > 0) return updatedDevice[0].status
    else return -1
  }

  const loopDeviceStatus = async (deviceId: number) => {
    repeat++
    const deviceStatus = await checkDeviceStatus(deviceId)

    if (repeat === 10 || deviceStatus === 1) {
      setLoading(false)
      repeat = 0
    } else checkTimeout(deviceId)
  }
  const checkTimeout = async (deviceId: number) => {
    setTimeout(() => loopDeviceStatus(deviceId), 3000)
  }

  const handleDisconnect = async (deviceId: number) => {
    setLoading(true)

    const deviceStatus = await checkDeviceStatus(deviceId)

    if (deviceStatus === 1) handleClose()

    await request(`device/disconnect/${deviceId}`, {}, {}, { method: 'GET' })

    checkTimeout(deviceId)
  }

  const handleGenerateQRCode = async (deviceId: number) => {
    setLoading(true)

    const deviceStatus = await checkDeviceStatus(deviceId)

    if (deviceStatus !== 0) {
      const resp = await request(`device/generate-qr-code/${deviceId}`, { device_fila })

      setTime(15)
      setQRCode(resp.qrCode)
    } else handleClose()

    setLoading(false)
  }

  if (device === null) return null

  return (
    <Modal open={device !== null} onClose={handleClose}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            maxWidth: '90%',
            maxHeight: '90%',
            background: '#fff',
            borderRadius: '8px',
            position: 'relative',
            padding: '40px',
            overflowY: 'auto',
          }}
        >
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '350px' }}>
            <div>
              <CircleIcon sx={{ fontSize: 12, color: device.status === 0 ? 'green' : 'red' }} /> &nbsp; {device.name}
            </div>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                {device.status === 0 ? (
                  <Button onClick={() => handleDisconnect(device.value)} variant="contained" color="error">
                    Desconectar
                  </Button>
                ) : qrCode === '' ? (
                  <>
                    <p style={{ fontSize: '12px', textAlign: 'justify', marginBottom: '8px' }}>
                      Ao conectar um novo dispositivo, você pode enviar mensagens antigas que foram registradas enquanto
                      o dispositivo estava desconectado.
                      <br />
                      <br />
                      Por favor, escolha como deseja proceder com essas mensagens pendentes:
                    </p>
                    <FormControl fullWidth size="small">
                      <TextField
                        name="device_id"
                        size="small"
                        label={`Selecione *`}
                        value={device_fila}
                        onChange={(e) => setDeviceFila(e.target.value)}
                        select
                        disabled={loading}
                      >
                        <MenuItem value={''}> &nbsp; </MenuItem>
                        <MenuItem value={1}>Apagar todas mensagens</MenuItem>
                        <MenuItem value={0}>Não apagar mensagens</MenuItem>
                        <MenuItem value={900}>Não apagar dos últimos 15 minutos</MenuItem>
                        <MenuItem value={3600}>Não apagar da última hora</MenuItem>
                        <MenuItem value={86400}>Não apagar das últimas 24 horas</MenuItem>
                      </TextField>
                    </FormControl>

                    {device_fila !== '' && (
                      <Button onClick={() => handleGenerateQRCode(device.value)} variant="contained">
                        Gerar QR Code
                      </Button>
                    )}
                  </>
                ) : (
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <img src={qrCode} width={350} height={350} alt="QR Code" />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <ScheduleIcon fontSize="small" /> &nbsp; 00:{time < 10 ? `0${time}` : time}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeviceModal
