import { FC, useEffect, useRef, useState } from 'react'
import { LinearProgress, ListItem, Paper, Grid, Box, Button } from '@mui/material'

import Loading from '../../../Loading'
import Message from './Message'

import CheckIcon from '@mui/icons-material/Check'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import RemoveDoneIcon from '@mui/icons-material/RemoveDone'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'

import { getExtension } from '../../../../utils/files'
import { request } from '../../../../utils/fetcher'

let messagesInterval: any
const MESSAGES_INITIAL_LIMIT = 50
const MESSAGES_INTERVAL_TIME = 5000
let messageUpdating = false
let messagesLimit = MESSAGES_INITIAL_LIMIT

const Messages: FC<{
  id: string
  scrollRef: any
  scheduleMessagesCount: number
  handleScheduleMessages: () => void
  refreshChatData: () => void
}> = ({ id, scrollRef, scheduleMessagesCount, handleScheduleMessages, refreshChatData }) => {
  const listRef = scrollRef
  const bottomRef = useRef<HTMLLIElement>(null)
  const [shouldScroll, setShouldScroll] = useState(true)
  const [oldMessagesLoading, setOldMessagesLoading] = useState(false)
  const [newMessagesLoading, setNewMessagesLoading] = useState(false)
  const [messagesLoading, setMessagesLoading] = useState(true)
  const [showSeeMore, setShowSeeMore] = useState(true)
  const [list, setMessagesList] = useState<any>([])
  const [openTranscript, setOpenTranscript] = useState<number[]>([])
  const timeLimit = 60 * 60 * 24

  useEffect(() => {
    reloadAllMessages(id || '')
  }, [])

  const clearMessagesInterval = () => {
    clearInterval(messagesInterval)
  }

  const reloadAllMessages = async (id: string) => {
    clearMessagesInterval()
    setMessagesList([])
    setMessagesLoading(true)
    setOldMessagesLoading(false)
    setNewMessagesLoading(false)
    messageUpdating = false
    messagesLimit = MESSAGES_INITIAL_LIMIT

    getAllMessages(id, messagesLimit)
    createMessagesInterval(id, messagesLimit)
  }

  useEffect(() => {
    if (messagesLoading) {
      listRef?.current?.removeEventListener('scroll', handleScroll)
      listRef?.current?.addEventListener('scroll', handleScroll)
    }

    if (!messagesLoading) {
      scrollBottom()
      setTimeout(() => scrollBottom(), 550)
    }
  }, [messagesLoading])

  useEffect(() => {
    if (newMessagesLoading) setShouldScroll(isOnBottom())

    if (!newMessagesLoading) {
      if (shouldScroll) {
        scrollBottom({ behavior: 'smooth' })
        setShouldScroll(false)
        handleSeeMessages()
      }
    }
  }, [newMessagesLoading])

  const handleSeeMessages = () => {
    seeLastMessages(id)
  }

  const getAllMessages = async (id: string, limit: number) => {
    if (!messageUpdating) {
      messageUpdating = true

      const resp = await request(`messages/${id}`, {}, {}, { method: 'GET', querystring: { limit } })

      resp.success && setMessagesList(resp.messages)

      if (resp.messages.length < limit) setShowSeeMore(false)
      else setShowSeeMore(true)

      setMessagesLoading(false)
      setNewMessagesLoading(false)
      setOldMessagesLoading(false)

      refreshChatData()

      messageUpdating = false
    }
  }

  const seeLastMessages = async (id: string) => {
    await request(`messages/${id}/read`)
  }

  const createMessagesInterval = (id: string, limit: number) => {
    messagesInterval = setInterval(() => {
      setNewMessagesLoading(true)
      getAllMessages(id, limit)
    }, MESSAGES_INTERVAL_TIME)
  }

  const incrementMessagesLimit = () => {
    setOldMessagesLoading(true)
    clearMessagesInterval()
    messagesLimit += MESSAGES_INITIAL_LIMIT
    createMessagesInterval(id, messagesLimit)
  }

  const handleScroll = (e: any) => {
    if (isOnBottom()) {
      handleSeeMessages()
    }
  }

  const scrollBottom = (type: any = false) => {
    listRef?.current?.scrollTo(0, bottomRef?.current?.offsetTop)
  }

  const isOnBottom = () => {
    const height = listRef?.current?.clientHeight || 0
    const scrolled = listRef?.current?.scrollTop || 0
    const scrollableHeight = listRef?.current?.scrollHeight || 0

    return height + scrolled === scrollableHeight
  }

  const getDateTime = (single: any) => {
    const { message_deleted_date, datetime_deleted, message_edited_date, datetime_edited, datetime, sendError } = single

    if (sendError === 1) return 'Não enviado'
    if (sendError === 2) return 'Telefone desconectado'
    if (message_deleted_date > 0) return `Deletado: ${datetime_deleted}`
    if (message_edited_date > 0) return `Editado: ${datetime_edited}`

    return datetime
  }

  const getSendingStatus = (single: any) => {
    const { message_deleted_date, device_status, message_id_external, message_created } = single

    const datetime = new Date().toLocaleString('en', { timeZone: 'America/Sao_Paulo' })
    const dateObj = new Date(datetime)
    const now = Math.floor(dateObj.getTime() / 1000)

    if (device_status === 0 && message_id_external === '0' && message_created + timeLimit < now) return 'Não enviado '
    if (message_deleted_date > 0) return ''
    if (message_id_external === '0' && device_status === 0) return 'Enviando '
    if (message_id_external === '0' && device_status > 0) return 'Telefone desconectado '

    return ''
  }

  const getMessageStatus = (single: any) => {
    const { message_status } = single
    let icon = <CheckIcon sx={{ fontSize: 12 }} />
    let color = 'inherit'
    let played = message_status === 'PLAYED'
    let read = message_status === 'READ'
    let received = message_status === 'RECEIVED'

    if (read || played) color = 'primary'

    if (received || read) icon = <DoneAllIcon color={color as any} sx={{ fontSize: 12 }} />
    if (played) icon = <PlayArrowIcon color={color as any} sx={{ fontSize: 12 }} />

    if (message_status === 'READ-SELF') icon = <RemoveDoneIcon color={'error'} sx={{ fontSize: 12 }} />

    return <span>{icon}</span>
  }

  const getMessageBackgroundColor = (single: any) => {
    const {
      message_deleted_date,
      message_edited_date,
      message_created,
      who_sent,
      sendError,
      message_id_external,
      device_status,
    } = single

    const datetime = new Date().toLocaleString('en', { timeZone: 'America/Sao_Paulo' })
    const dateObj = new Date(datetime)
    const now = Math.floor(dateObj.getTime() / 1000)

    if (device_status === 0 && message_id_external === '0' && message_created + timeLimit < now) return '#efdcdc'
    if (message_deleted_date > 0 || sendError === 1 || sendError === 2) return '#efdcdc'
    if (message_edited_date > 0) return '#ebefdc'
    if (message_id_external === '0') return '#f0f0f0'

    return who_sent !== 0 ? '#d0f2d0' : '#e2dcef'
  }

  const getPositionWhoSent = (single: any) => {
    const { who_sent } = single

    return who_sent !== 0 ? 'end' : 'start'
  }

  if (messagesLoading) return <Loading />

  return (
    <>
      <ul>
        <Box sx={{ width: '100%', height: '4px', padding: '7px 20px' }}>{oldMessagesLoading && <LinearProgress />}</Box>

        {!oldMessagesLoading && showSeeMore && (
          <Box sx={{ textAlign: 'center' }}>
            <Button onClick={incrementMessagesLimit}>Ver Mais +</Button>
          </Box>
        )}

        {!messagesLoading &&
          list.length > 0 &&
          list.map((single: any, i: number) => {
            const {
              message_id,
              system_log,
              who_sent,
              employee_name,
              message_type,
              message,
              metatags_url,
              message_reaction,
              sent_from,
              message_dropdown_content,
              isGroup,
              senderName,
            } = single
            const transcripted_message = message_dropdown_content.split('##')

            if (system_log === 1)
              return (
                <div key={i} style={{ textAlign: 'center', padding: '20px 0', width: '100%' }}>
                  <p style={{ fontSize: '0.7rem' }}>{message}</p>
                  <span style={{ fontSize: '0.55rem' }}>{getDateTime(single)}</span>
                </div>
              )
            else
              return (
                <ListItem key={i}>
                  <Grid container>
                    <div style={{ display: 'flex', justifyContent: getPositionWhoSent(single), width: '100%' }}>
                      <Paper
                        elevation={3}
                        sx={{
                          background: getMessageBackgroundColor(single),
                          padding: '7px',
                          maxWidth: '60%',
                          minWidth: '140px',
                          overflow: 'auto',
                          height: 'auto',
                          wordBreak: 'break-word',
                        }}
                      >
                        <Grid item xs={12}>
                          {who_sent !== 0 && (
                            <strong>
                              <p style={{ fontSize: '0.6rem', marginBottom: '5px', color: '#666' }}>
                                {who_sent !== null
                                  ? employee_name
                                  : sent_from === 'D'
                                  ? 'Enviado do Device'
                                  : 'Enviado do Sistema'}
                                :
                              </p>
                            </strong>
                          )}
                          {isGroup !== 0 && senderName !== '' && (
                            <strong>
                              <p style={{ fontSize: '0.6rem', marginBottom: '5px' }}>{senderName}:</p>
                            </strong>
                          )}
                          <div className="format-message">
                            <Message key={message_id} type={message_type} data={single}>
                              {message}
                            </Message>

                            {metatags_url !== '0' && (
                              <div className="meta" dangerouslySetInnerHTML={{ __html: metatags_url }} />
                            )}

                            {message_type === 1 &&
                              ['acc', 'amr', 'mpeg', 'ogg', 'wav', 'mp3', 'webm'].includes(
                                getExtension(message) || ''
                              ) &&
                              message_dropdown_content !== '0' && (
                                <div>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{ fontSize: '0.65rem' }}
                                    onClick={() => {
                                      if (openTranscript.includes(message_id))
                                        setOpenTranscript(openTranscript.filter((n) => n !== message_id))
                                      else setOpenTranscript([...openTranscript, message_id])
                                    }}
                                  >
                                    {openTranscript.includes(message_id) ? 'Fechar transcrição' : 'Ver transcrição'}
                                  </Button>

                                  {openTranscript.includes(message_id) && (
                                    <div className="meta">
                                      <h4>Transcrição</h4>
                                      <p>{transcripted_message[0]}</p>
                                      {transcripted_message[1] && transcripted_message[0].length > 900 && (
                                        <>
                                          <br />

                                          <p>
                                            <TipsAndUpdatesIcon sx={{ fontSize: 14 }} /> {transcripted_message[1]}
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>

                          <div
                            style={{
                              fontSize: '0.6rem',
                              color: '#999',
                              textAlign: 'right',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '3px',
                              paddingTop: '3px',
                              position: 'relative',
                            }}
                          >
                            {getSendingStatus(single)}
                            {getDateTime(single)}
                            {getMessageStatus(single)}

                            {message_reaction !== '0' && (
                              <div
                                style={{
                                  position: 'absolute',
                                  background: 'rgba(255, 255, 255, 0.8)',
                                  borderRadius: '50%',
                                  left: -3,
                                  bottom: -3,
                                  padding: '3px',
                                }}
                              >
                                {message_reaction}
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Paper>
                    </div>
                  </Grid>
                </ListItem>
              )
          })}
        <Box sx={{ width: '100%', height: '1px', overflowY: 'hidden' }}>{newMessagesLoading && <LinearProgress />}</Box>
        <ListItem ref={bottomRef} sx={{ height: '1px', padding: 0 }} />
      </ul>
      {scheduleMessagesCount !== 0 && (
        <div
          style={{
            position: 'fixed',
            bottom: '101px',
            width: '-webkit-fill-available',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleScheduleMessages}
            sx={{
              textTransform: 'inherit',
              fontSize: 10,
              background: '#fff',
            }}
          >
            {scheduleMessagesCount} Mensagen(s) agendada(s)
          </Button>
        </div>
      )}
    </>
  )
}

export default Messages
