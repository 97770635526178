import { FC, useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import SendIcon from '@mui/icons-material/Send'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Button from '@mui/material/Button'
import MicIcon from '@mui/icons-material/Mic'
import ZoomInIcon from '@mui/icons-material/ZoomIn'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EditCalendarIcon from '@mui/icons-material/EventAvailable'

import { request } from '../../../../utils/fetcher'
import Modal from '../Modal'

const TextForm: FC<{
  id: number | string
  disabled: boolean
  scheduleDate: any
  handleScheduleAction: () => void
  clearScheduleDate: () => void
  areaHeight: string | number
  disabledAction: any
}> = ({ id, disabled, scheduleDate, handleScheduleAction, clearScheduleDate, areaHeight, disabledAction }) => {
  const [text, setText] = useState('')
  const [mouseEnter, setMouseEnter] = useState(false)
  const [showDate, setShowDate] = useState(false)
  const [sending, setSending] = useState(false)
  const [modalOpen, setModalOpen] = useState('')

  useEffect(() => {
    if (mouseEnter)
      setTimeout(() => {
        setShowDate(true)
      }, 600)
    else setShowDate(false)
  }, [mouseEnter])

  const handleCloseModal = () => {
    setModalOpen('')
  }

  const handleChange = (e: any) => {
    setText(e.target.value)
  }

  const handleSubmitText = async () => {
    if (!sending && !disabled) {
      setSending(true)

      const resp = await request(`messages/${id}/send-message`, {
        text,
        scheduleDate: scheduleDate ? scheduleDate.unix() : 0,
      })

      if (resp.success && Number(resp.message) > 0) {
        request(`messages/${id}/send-whatsapp`, { text: resp.text, messageId: resp.message })
      }

      setText('')
      clearScheduleDate()

      setSending(false)
    }
  }

  const handleSubmitImages = async (files: any) => {
    if (!sending && !disabled) {
      setSending(true)

      files.forEach((file: any, i: number) => {
        setTimeout(async () => {
          const resp = await request(`messages/${id}/send-message`, {
            scheduleDate: scheduleDate ? scheduleDate.unix() : 0,
            text: file,
            messageType: 1,
          })

          if (resp.success && Number(resp.message) > 0) {
            request(`messages/${id}/send-whatsapp`, { text: file, messageId: resp.message, media: 1 })
          }
        }, i * 1000)
      })

      clearScheduleDate()
      setSending(false)
      handleCloseModal()
    }
  }

  let boxStyle = {}
  if (scheduleDate)
    boxStyle = {
      background: '#fff',
      boxShadow: '1px 1px 3px #ccc',
    }

  let buttonStyle = {
    width: '32px',
    minWidth: '32px',
  }
  if (mouseEnter)
    buttonStyle = {
      width: '140px',
      minWidth: '140px',
    }

  const iconStyle = {
    fontSize: 20,
    color: scheduleDate ? '#2e7d32' : '#ccc',
  }

  const textFieldHeight = '30px'

  return (
    <>
      <Modal
        clientId={id}
        modalOpen={modalOpen}
        handleClose={handleCloseModal}
        handleSubmitImages={handleSubmitImages}
        handleScheduleFiles={handleScheduleAction}
        clearScheduleDate={clearScheduleDate}
        scheduleDate={scheduleDate}
        sendingOnlyAudio={sending}
        messagesModal={{
          handleChange,
          handleSubmitText,
          text,
        }}
      />

      <Grid container>
        {disabled && (
          <div
            style={{
              flex: 1,
              maxHeight: areaHeight,
              height: areaHeight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#f8f8f8',
            }}
          >
            <Button variant="outlined" onClick={() => disabledAction()}>
              Opções de Atendimento
            </Button>
          </div>
        )}
        {!disabled && (
          <>
            <Grid item sx={{ maxHeight: areaHeight, height: areaHeight, overflowY: 'hidden', flex: 1 }}>
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '2px',
                    ...boxStyle,
                  }}
                >
                  <Button
                    disabled={sending || disabled}
                    onClick={() => {
                      !sending && !disabled && handleScheduleAction()
                    }}
                    onMouseEnter={() => {
                      if (scheduleDate) setMouseEnter(true)
                    }}
                    onMouseLeave={() => {
                      setMouseEnter(false)
                    }}
                    sx={{
                      transition: 'width 550ms, min-width 550ms',
                      justifyContent: 'flex-end',
                      padding: '5px',
                      ...buttonStyle,
                    }}
                  >
                    {scheduleDate && showDate ? (
                      <span style={{ fontSize: '12px' }}>{scheduleDate.format('DD/MM/YYYY HH:mm')}&nbsp;</span>
                    ) : null}{' '}
                    {scheduleDate ? <EditCalendarIcon sx={iconStyle} /> : <CalendarMonthIcon sx={iconStyle} />}
                  </Button>
                </div>

                <div
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '35px',
                  }}
                >
                  <Button onClick={() => setModalOpen('messages')} sx={{ width: '32px', minWidth: '32px' }}>
                    <ZoomInIcon sx={{ fontSize: 20, color: '#ccc' }} />
                  </Button>
                </div>

                <textarea
                  placeholder="Mensagem:"
                  value={text}
                  onChange={handleChange}
                  readOnly={sending || disabled}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      !sending && !disabled && handleSubmitText()
                    }
                  }}
                  style={{
                    backgroundColor: sending || disabled ? '#e8e8e8' : '#fff',
                    color: sending || disabled ? '#666' : '#333',
                    border: 'none',
                    height: `calc(${areaHeight} - ${textFieldHeight})`,
                    width: '100%',
                    padding: '7px 30px 7px 12px',
                    maxHeight: `calc(${areaHeight} - ${textFieldHeight})`,
                    resize: 'none',
                    fontSize: '14px',
                  }}
                ></textarea>
              </div>
            </Grid>

            <Grid item sx={{ width: '56px' }}>
              <Fab
                onClick={() => {
                  !sending && !disabled && handleSubmitText()
                }}
                color="primary"
                sx={{
                  borderRadius: '0',
                  height: areaHeight,
                  backgroundColor: sending || disabled ? '#ccc' : '',
                }}
              >
                <SendIcon sx={{ fontSize: 16 }} />
              </Fab>
            </Grid>

            <Grid item sx={{ width: '56px' }}>
              <Fab
                onClick={() => {
                  !sending && !disabled && setModalOpen('audio-record')
                }}
                color="info"
                sx={{
                  borderRadius: '0',
                  height: areaHeight,
                  backgroundColor: sending || disabled ? '#ccc' : '',
                  color: sending || disabled ? '#999' : '#fff',
                }}
              >
                <MicIcon sx={{ fontSize: 22 }} />
              </Fab>
            </Grid>

            <Grid item sx={{ width: '56px' }}>
              <Fab
                onClick={() => {
                  !sending && !disabled && setModalOpen('upload')
                }}
                sx={{
                  borderRadius: '0',
                  height: areaHeight,
                  color: sending || disabled ? '#999' : '#222',
                }}
              >
                <AttachFileIcon sx={{ fontSize: 22 }} />
              </Fab>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default TextForm
