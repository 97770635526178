import { FC, useContext, useEffect, useRef, useState } from 'react'
import { AudioElement, SpeedRate } from './styles'
import { Button, Menu, MenuItem } from '@mui/material'
import { DataContext } from '../../../../../../../context'
import { request } from '../../../../../../../utils/fetcher'

const Audio: FC<{ src: string; extension: string; size?: { width: number; height: number }; data?: any }> = ({
  src,
  extension,
  size,
  data,
}) => {
  const { handleChangeUserPreferenceAudioSpeed, userPreferenceAudioSpeed } = useContext(DataContext)
  const audioRef = useRef<HTMLAudioElement>(null)
  const width = size?.width ? size.width : '300'
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const [transcript, setTranscript] = useState<number[]>([])

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    changeLocalAudioSpeed(userPreferenceAudioSpeed)
  }, [userPreferenceAudioSpeed])

  const handleChangeSpeed = (speed: number) => {
    handleChangeUserPreferenceAudioSpeed(speed)
    handleClose()
  }

  const changeLocalAudioSpeed = (speed: number) => {
    if (audioRef.current) audioRef.current.playbackRate = speed
  }

  const handleTranscript = async (message_id: number) => {
    setTranscript([...transcript, message_id])

    const resp = await request(`audio/transcript/${message_id}`)

    if (!resp.success) setTranscript(transcript.filter((n) => n !== message_id))
  }

  const getTranscriptComponent = (data: any) => {
    if (data !== undefined) {
      const { message_id, cron_transcript, message_dropdown_content, who_sent } = data

      return (
        <>
          {message_dropdown_content === '0' && who_sent === 0 && (
            <Button
              disabled={cron_transcript === 1 || transcript.includes(message_id)}
              variant="outlined"
              size="small"
              sx={{ fontSize: '0.65rem' }}
              onClick={() => handleTranscript(message_id)}
            >
              {cron_transcript === 1 || transcript.includes(message_id) ? 'Transcrevendo' : 'Enviar para transcrição'}
            </Button>
          )}
        </>
      )
    }

    return null
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <div style={{ display: 'flex', alignItems: 'center', width: `${width}px` }}>
        <AudioElement ref={audioRef} controls>
          <source src={src} type={`audio/${extension}`} />
          Your browser does not support the video tag.
        </AudioElement>
        <SpeedRate>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ height: '100%', fontSize: 11 }}
          >
            {userPreferenceAudioSpeed.toFixed(1)}x
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{ textAlign: 'center', width: '100%' }}
          >
            <MenuItem style={{ fontSize: 11 }} onClick={() => handleChangeSpeed(0.5)}>
              0.5x
            </MenuItem>
            <MenuItem style={{ fontSize: 11 }} onClick={() => handleChangeSpeed(1)}>
              1.0x
            </MenuItem>
            <MenuItem style={{ fontSize: 11 }} onClick={() => handleChangeSpeed(1.5)}>
              1.5x
            </MenuItem>
            <MenuItem style={{ fontSize: 11 }} onClick={() => handleChangeSpeed(2)}>
              2.0x
            </MenuItem>
            <MenuItem style={{ fontSize: 11 }} onClick={() => handleChangeSpeed(2.5)}>
              2.5x
            </MenuItem>
            <MenuItem style={{ fontSize: 11 }} onClick={() => handleChangeSpeed(3)}>
              3.0x
            </MenuItem>
          </Menu>
        </SpeedRate>
      </div>

      <div>{getTranscriptComponent(data)}</div>
    </div>
  )
}

export default Audio
