import { FC, useState, useEffect, useRef } from 'react'

import Button from '@mui/material/Button'
import { Fab, IconButton, LinearProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { getExtension } from '../../../../../utils/files'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EditCalendarIcon from '@mui/icons-material/EventAvailable'
import { request } from '../../../../../utils/fetcher'
import AudioRecord from '../AudioRecord'
import TableFixedFiles from '../../../../TableFixedFiles'

const Upload: FC<{
  id: any
  handleClose: () => void
  handleScheduleFiles: () => void
  scheduleDate: any
  handleSubmitImages: any
  record?: boolean
  clearScheduleDate: any
  onlyAudio?: boolean
  sendingOnlyAudio?: boolean
}> = ({
  id,
  handleClose,
  handleScheduleFiles,
  scheduleDate,
  handleSubmitImages,
  record,
  clearScheduleDate,
  onlyAudio = false,
  sendingOnlyAudio = false,
}) => {
  const drop = useRef<HTMLDivElement>(null)
  const fileInput = useRef<any>(null)
  const [fileList, setFileList] = useState<any[]>([])
  const [filesUpload, setFilesUpload] = useState<any[]>([])
  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(true)
  const [fixedFiles, setFixedFiles] = useState<any[]>([])

  const loadAllFixedFiles = async () => {
    const data = await request(`company/fixed-files`, {}, {}, { method: 'GET' })

    if (data.success && data.files.length > 0) setFixedFiles(data.files)

    setLoading(false)
  }

  const pushFile = (file: any) => {
    let newList = fileList
    let listUpload = filesUpload

    const ext = getExtension(file.name)

    newList.push(
      ext === 'jpg' || ext === 'png' || ext === 'jpeg' || ext === 'gif' || ext === 'webp'
        ? URL.createObjectURL(file)
        : file.name
    )
    listUpload.push(file)

    setFileList(newList)
    setFilesUpload(listUpload)
  }

  const handleRemoveList = (i: number) => {
    setFileList(fileList.filter((src: any, k: number) => i !== k))
    setFilesUpload(filesUpload.filter((src: any, k: number) => i !== k))
  }

  useEffect(() => {
    loadAllFixedFiles()

    drop.current?.addEventListener('dragover', handleDragOver)
    drop.current?.addEventListener('drop', handleDrop)

    return () => {
      drop.current?.removeEventListener('dragover', handleDragOver)
      drop.current?.removeEventListener('drop', handleDrop)
    }
  }, [])

  const handleDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    if (files && files.length) {
      Object.keys(files).forEach((key: any) => pushFile(files[key]))
    }
  }

  const handleFile = (e: any) => {
    const files = []

    if (e.target.files.length > 5) alert('Você só pode enviar 5 arquivos por vez.')

    if (e?.target?.files)
      for (let i = 0; i < e.target.files.length && i < 5; i++) {
        files.push(e.target.files[i])
      }

    if (files.length > 0) {
      files.forEach((file: any) => pushFile(file))
    }
  }

  const uploadFiles = async (list: any) => {
    return await request(
      `messages/${id}/upload-files`,
      {
        file: list,
      },
      {},
      { upload: true }
    )
  }

  const handleAttachAudio = (blob: any) => {
    const file = new File([blob], `audio.webm`, { type: blob.type })
    pushFile(file)
  }

  const handleAttachFiles = async () => {
    let fileSizeError = ''

    filesUpload.forEach((file) => {
      if (file.size > 15 * 1024 * 1024) fileSizeError = file.name
    })

    if (fileSizeError === '') {
      setSending(true)
      const data = await uploadFiles(filesUpload)

      if (data.success && data.files.length > 0) {
        await handleSubmitImages(data.files)
      }

      setSending(false)
      handleClose()
      clearScheduleDate()
    } else {
      alert(`O arquivo ${fileSizeError} excedeu o limite de upload (15MB).`)
    }
  }

  const handleSendFixedFile = async (file: string) => {
    setSending(true)

    await handleSubmitImages([file])

    setSending(false)
    handleClose()
    clearScheduleDate()
  }

  const handleClear = () => {
    setFileList([])
    setFilesUpload([])
  }

  const iconStyle = {
    fontSize: 24,
    color: scheduleDate ? '#2e7d32' : '#ccc',
  }

  return (
    <>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton
          onClick={() => {
            handleClose()
            !onlyAudio && clearScheduleDate()
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      {!onlyAudio && (
        <div
          ref={drop}
          style={{
            width: '70vw',
            border: '1px dashed',
            borderColor: sending ? '#ccc' : '#000',
            color: sending ? '#ccc' : '#000',
            padding: '40px 20px 10px',
            marginBottom: '40px',
          }}
        >
          <ul
            style={{
              display: 'flex',
              gap: '15px',
              marginBottom: '20px',
              flexWrap: 'wrap',
            }}
          >
            {fileList.map((file: any, i: number) => {
              const ext = getExtension(file)

              return (
                <li
                  key={i}
                  style={{
                    position: 'relative',
                  }}
                >
                  <Fab
                    onClick={(e) => handleRemoveList(i)}
                    size="small"
                    color="primary"
                    sx={{
                      position: 'absolute',
                      top: -10,
                      right: -10,
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 18 }} />
                  </Fab>

                  {file.includes('blob') && (
                    <img
                      alt="arquivo"
                      src={file}
                      width={'100'}
                      style={{
                        border: '1px solid #f8f8f8',
                        boxShadow: '1px 1px 3px #ccc',
                        padding: '5px',
                      }}
                    />
                  )}
                  {!file.includes('blob') && (
                    <div
                      title={file}
                      style={{
                        width: '100px',
                        height: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #f8f8f8',
                        boxShadow: '1px 1px 3px #ccc',
                        padding: '5px',
                        flexDirection: 'column',
                      }}
                    >
                      <InsertDriveFileIcon />
                      <span>.{ext}</span>
                    </div>
                  )}
                  {sending ? <LinearProgress /> : null}
                </li>
              )
            })}
          </ul>

          <input style={{ display: 'none' }} ref={fileInput} type="file" onChange={handleFile} multiple />

          <div
            style={{
              cursor: 'pointer',
              width: '100%',
              gap: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => (sending ? () => {} : fileInput.current?.click())}
          >
            {fileList.length === 0 && (
              <>
                <span> Arraste um arquivo ou clique para realizar o upload. </span>{' '}
                <CloudUploadIcon fontSize={'medium'} />
              </>
            )}
            {fileList.length > 0 && <AddCircleIcon fontSize="large" />}
          </div>

          <div style={{ margin: '40px 0 0 0', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={sending}
              onClick={() => {
                handleClear()
              }}
              size="small"
              sx={{ width: 'auto' }}
            >
              Limpar
            </Button>
          </div>
        </div>
      )}

      {sending ? (
        <div style={{ width: '150px' }}>
          <LinearProgress />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 5px',
            marginBottom: '15px',
            minWidth: '150px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              cursor: 'pointer',
              marginRight: '16px',
            }}
            onClick={sending ? () => {} : handleScheduleFiles}
          >
            {scheduleDate ? <EditCalendarIcon sx={iconStyle} /> : <CalendarMonthIcon sx={iconStyle} />}
            {scheduleDate ? (
              <span style={{ fontSize: '14px' }}>{scheduleDate.format('DD/MM/YYYY HH:mm')}&nbsp;</span>
            ) : null}
          </div>

          <AudioRecord
            record={record}
            handleAttach={handleAttachAudio}
            handleSend={handleAttachFiles}
            onlyAudio={onlyAudio}
          />
        </div>
      )}

      {!onlyAudio && (
        <>
          <div style={{ margin: '0 0 20px 0', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={sending || filesUpload.length === 0}
              onClick={() => {
                handleAttachFiles()
              }}
              variant="contained"
              size="small"
              sx={{ width: 'auto' }}
            >
              Enviar Anexos
            </Button>
          </div>

          {loading && <LinearProgress />}
          {fixedFiles.length > 0 && fixedFiles[0].FixedFiles_id !== null && (
            <div style={{ height: '300px', overflow: 'auto' }}>
              <TableFixedFiles files={fixedFiles} size="xsmall" handleSend={handleSendFixedFile} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Upload
