import { useContext, useEffect } from 'react'
import { Routes as BrowserRoutes, Route, useNavigate, useLocation } from 'react-router-dom'
import Chat from './pages/Chat'
import Login from './pages/Login'
import Files from './pages/Files'
import PageNotFound from './pages/PageNotFound'
import DefaultMessages from './pages/DefaultMessages'
import Settings from './pages/Settings'
import Profile from './pages/Profile'
import ForgotPassword from './pages/ForgotPassword'
import RecoverPassword from './pages/RecoverPassword'
import Graphs from './pages/Graphs'
import { FilterContext } from './context/filter'

const Routes = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { handleLogout, handleLogin } = useContext(FilterContext)

  useEffect(() => {
    window.addEventListener('storage', () => {
      if (
        (localStorage.getItem('x-auth-chat') === '' ||
          localStorage.getItem('x-auth-chat') === null ||
          localStorage.getItem('x-auth-chat') === undefined) &&
        location.pathname !== '/forgot-password' &&
        location.pathname.indexOf('recover-password') === -1
      ) {
        navigate('/login')
        handleLogout()
      } else {
        handleLogin()
      }
    })
    window.dispatchEvent(new Event('storage'))
  }, [])

  return (
    <BrowserRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/recover-password/:id" element={<RecoverPassword />} />
      <Route path="*" element={<PageNotFound />} />
      <Route index element={<Chat list={true} />} />
      <Route path="/chats" element={<Chat list={true} />} />
      <Route path="/chat/:id" element={<Chat />} />
      <Route path="/files" element={<Files />} />
      <Route path="/default-messages" element={<DefaultMessages />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/graphs" element={<Graphs />} />
    </BrowserRoutes>
  )
}

export default Routes
