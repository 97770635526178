import { FC, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionActions,
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  LinearProgress,
  Fab,
  Alert,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { request } from '../../utils/fetcher'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

const Ura: FC = () => {
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [menus, setMenus] = useState<any[]>([])
  const [users, setUsers] = useState<any[]>([])
  const [departments, setDepartments] = useState<any[]>([])
  const [device_id, setDeviceId] = useState('')
  const [devices, setDevices] = useState([])
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    loadAllUsers()
    loadAllDepartments()
    loadAllDevices()
  }, [])

  useEffect(() => {
    if (device_id !== '') loadURATree()
  }, [device_id])

  const loadURATree = async () => {
    setLoading(true)
    const data = await request(`company/get-ura/${device_id}`, {}, {}, { method: 'GET' })
    setMenus(data.menus)
    setLoading(false)
  }

  const loadAllDevices = async () => {
    const data = await request(`company/get-devices`, {}, {}, { method: 'GET' })
    setDevices(data.devices)
  }

  const loadAllUsers = async () => {
    const data = await request(`company/get-users/`, {}, {}, { method: 'GET' })

    if (data.success && data.users.length > 0)
      setUsers(
        data.users.map((user: any) => {
          return {
            value: user.employee_id,
            name: user.employee_name,
          }
        })
      )
  }

  const loadAllDepartments = async () => {
    const data = await request(`company/get-all-company-departments/`, {}, {}, { method: 'GET' })

    if (data.success && data.departments.length > 0) setDepartments(data.departments)
  }

  const countTree = (tree: any): any => {
    if (tree.length === 0) return 0

    let count = 1

    if (tree[0].children.length > 0) {
      tree[0].children.forEach((children: any) => {
        count += countTree([children])
      })
    }

    return count
  }

  const countButtons = (tree: any, id: number): any => {
    if (tree.length === 0) return 0

    if (tree[0].id === id) {
      return tree[0].buttons.length
    }

    let count = 0

    if (tree[0].children.length > 0) {
      tree[0].children.forEach((children: any) => {
        count += countButtons([children], id)
      })
    }

    return count
  }

  const updateTree = (id: number, tree: any, children: any): any => {
    if (tree.id === id) {
      return {
        ...tree,
        children: [...tree.children, children],
      }
    }

    if (tree.children.length > 0) {
      return {
        ...tree,
        children: tree.children.map((child: any) => updateTree(id, child, children)),
      }
    }

    return tree
  }

  const removeMenu = (id: number, tree: any) => {
    if (tree.id !== id) {
      if (tree.children.length > 0) {
        return {
          ...tree,
          children: tree.children.filter((child: any) => child.id !== id).map((child: any) => removeMenu(id, child)),
        }
      }
    }

    return tree
  }

  const updateTreeButton = (id: number, tree: any, button: any): any => {
    if (tree.id === id) {
      return {
        ...tree,
        buttons: [...tree.buttons, button],
      }
    }

    if (tree.children.length > 0) {
      return {
        ...tree,
        children: tree.children.map((child: any) => updateTreeButton(id, child, button)),
      }
    }

    return tree
  }

  const removeTreeButton = (idMenu: number, id: number, tree: any): any => {
    if (tree.id === idMenu) {
      return {
        ...tree,
        buttons: tree.buttons.filter((button: any) => button.id !== id),
      }
    }

    if (tree.children.length > 0) {
      return {
        ...tree,
        children: tree.children.map((child: any) => removeTreeButton(idMenu, id, child)),
      }
    }

    return tree
  }

  const updateTreeButtonById = (idMenu: number, id: number, tree: any, name: string, value: string): any => {
    if (tree.id === idMenu) {
      return {
        ...tree,
        buttons: tree.buttons.map((button: any) => {
          if (button.id === id) return { ...button, [name]: value }
          else return button
        }),
      }
    }

    if (tree.children.length > 0) {
      return {
        ...tree,
        children: tree.children.map((child: any) => updateTreeButtonById(idMenu, id, child, name, value)),
      }
    }

    return tree
  }

  const updateTreeMenuById = (idMenu: number, tree: any, name: string, value: string): any => {
    if (tree.id === idMenu) {
      return {
        ...tree,
        [name]: value,
      }
    }

    if (tree.children.length > 0) {
      return {
        ...tree,
        children: tree.children.map((child: any) => updateTreeMenuById(idMenu, child, name, value)),
      }
    }

    return tree
  }

  const handleDragButtonsEnd = (idMenu: number, buttons: any) => {
    const newTree = menus.map((menu: any) => updateTreeMenuById(idMenu, menu, 'buttons', buttons))
    setMenus(newTree)
  }

  const handleAddChildren = (id: number, callback?: any) => {
    const count = countTree(menus)
    const children = {
      id: count + 0.1,
      name: `Menu ${count}`,
      content: '',
      header: '',
      footer: '',
      buttons: [],
      children: [],
    }
    const newTree = menus.map((menu) => updateTree(id, menu, children))

    setMenus(newTree)

    callback && callback(count, newTree)
  }

  const handleCreateURA = () => {
    const menu = { id: 0, name: `Início`, content: '', header: '', footer: '', buttons: [], children: [] }
    setMenus([menu])
  }

  const handleRemoveMenu = (id: number) => {
    const newTree = menus.map((menu) => removeMenu(id, menu))
    setMenus(newTree)
  }

  const handleAddNewButton = (id: number) => {
    const count = countButtons(menus, id)
    const button = { id: count + 0.1, name: '', type: '', idRelated: '' }
    const newTree = menus.map((menu) => updateTreeButton(id, menu, button))
    setMenus(newTree)
  }

  const handleRemoveButton = (id: number, idButton: number) => {
    const newTree = menus.map((menu) => removeTreeButton(id, idButton, menu))
    setMenus(newTree)
  }

  const onChange = (name: string, value: string, id: number, idMenu: number, tree: any) => {
    const newTree = tree.map((menu: any) => updateTreeButtonById(idMenu, id, menu, name, value))
    setMenus(newTree)
  }

  const onChangeMenu = (name: string, value: string, idMenu: number, tree: any) => {
    let key: any

    if (name === 'title') key = 'name'
    else key = name

    const newTree = tree.map((menu: any) => updateTreeMenuById(idMenu, menu, key, value))
    setMenus(newTree)
    return newTree
  }

  const validateMenu = (tree: any, errors: string[]) => {
    if (tree.name.trim() === '') {
      const msg = 'Título do menu'
      if (!errors.includes(msg)) errors.push(msg)
    }
    if (tree.content.trim() === '') {
      const msg = 'Mensagem'
      if (!errors.includes(msg)) errors.push(msg)
    }
    if (tree.content.length > 3000) {
      const msg = 'Mensagem com mais de 3000 caracteres'
      if (!errors.includes(msg)) errors.push(msg)
    }
    if (tree.header.length > 20) {
      const msg = 'Cabeçalho com mais de 20 caracteres'
      if (!errors.includes(msg)) errors.push(msg)
    }
    if (tree.footer.length > 60) {
      const msg = 'Rodapé com mais de 60 caracteres'
      if (!errors.includes(msg)) errors.push(msg)
    }
    if (tree.buttons.length > 0) {
      tree.buttons.forEach((btn: any) => {
        if (btn.name.trim() === '') {
          const msg = 'Label dos botões'
          if (!errors.includes(msg)) errors.push(msg)
        }
        if (btn.name.trim().length > 25) {
          const msg = 'Label dos botões com mais de 25 caracteres'
          if (!errors.includes(msg)) errors.push(msg)
        }
        if (btn.type === '') {
          const msg = 'Tipo dos botões'
          if (!errors.includes(msg)) errors.push(msg)
        }
        if (btn.idRelated === '' && btn.type !== '') {
          const msg =
            btn.type === 1
              ? 'Campo Usuário dos botões'
              : btn.type === 2
              ? 'Campo Departamento dos botões'
              : 'Campo Menu dos botões'
          if (!errors.includes(msg)) errors.push(msg)
        }
      })
    }

    if (tree.children.length > 0) {
      tree.children.map((child: any) => validateMenu(child, errors))
    }

    return errors
  }

  const validateMenus = (tree: any) => {
    const newErrors = tree.map((menu: any) => validateMenu(menu, errors))
    setErrors(newErrors[0])
  }

  useEffect(() => {
    if (errors.length === 0 && sending) {
      validateMenus(menus)
      submit()
    }
  }, [errors, sending])

  const handleSave = async () => {
    setSending(true)
    setErrors([])
  }

  const submit = async () => {
    console.log(menus)
    //await request(`company/save-ura`, { menus, device_id })
    setSending(false)
  }

  return (
    <Box>
      {errors.length > 0 && (
        <Alert severity="error" sx={{ marginBottom: '20px' }}>
          Preencha corretamente os campos: <strong>{errors.join(', ')}</strong>.
        </Alert>
      )}
      {(loading || sending) && <LinearProgress />}
      {!loading && !sending && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '0 0 20px 0' }}>
            <TextField
              variant="standard"
              name="device_id"
              size="small"
              label={`Número do Telefone *`}
              value={device_id}
              select
              onChange={(e) => setDeviceId(e.target.value)}
              sx={{
                width: '180px',
              }}
            >
              {devices.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {menus.length === 0 && device_id !== '' && (
              <Button
                onClick={() => {
                  handleCreateURA()
                }}
                variant="contained"
                size="small"
                sx={{ width: 'auto' }}
              >
                Criar URA &nbsp; <AddCircleOutlineIcon fontSize={'small'} />
              </Button>
            )}
            {menus.length > 0 && device_id !== '' && (
              <Button
                onClick={() => {
                  handleSave()
                }}
                variant="contained"
                size="small"
                sx={{ width: 'auto' }}
              >
                Salvar URA
              </Button>
            )}
          </div>
          {device_id !== '' && (
            <ChildrenComponent
              users={users}
              departments={departments}
              tree={menus}
              menus={menus}
              handleAddNewButton={handleAddNewButton}
              handleAddChildren={handleAddChildren}
              onChange={onChange}
              onChangeMenu={onChangeMenu}
              handleRemoveButton={handleRemoveButton}
              handleRemoveMenu={handleRemoveMenu}
              handleDragButtonsEnd={handleDragButtonsEnd}
            />
          )}
        </>
      )}
    </Box>
  )
}

let ChildrenComponent: FC<{
  tree: any
  menus: any
  handleAddChildren: any
  handleAddNewButton: any
  onChange: any
  users: any[]
  departments: any[]
  onChangeMenu: any
  handleRemoveButton: any
  handleRemoveMenu: any
  handleDragButtonsEnd: any
}> = ({
  tree,
  menus,
  handleAddChildren,
  handleAddNewButton,
  onChange,
  users,
  departments,
  onChangeMenu,
  handleRemoveButton,
  handleRemoveMenu,
  handleDragButtonsEnd,
}) => {
  const listMenus = (tree: any) => {
    let arr: any[] = []

    const searchTree = (tree: any, arr: any[]) => {
      if (tree.length > 0) {
        arr.push({
          value: tree[0].id,
          name: tree[0].name,
        })

        if (tree[0].children.length > 0) {
          tree[0].children.forEach((n: any) => {
            searchTree([n], arr)
          })
        }
      }
    }

    searchTree(tree, arr)

    return arr
  }

  const buttonOptions = [
    {
      value: 3,
      name: 'Menu',
      options: [
        {
          value: 'new',
          name: 'Novo Menu',
        },
        ...listMenus(menus),
      ],
    },
    {
      value: 2,
      name: 'Departamento',
      options: departments,
    },
    {
      value: 1,
      name: 'Usuário',
      options: users,
    },
  ]

  const getSelectIdRelatedComponent = (button: any, id: number) => {
    const search = buttonOptions.filter((item) => item.value === button.type)

    if (search.length === 0) return null

    const menu = search[0]

    return (
      <FormControl fullWidth size="small">
        <TextField
          variant="standard"
          name="idRelated"
          size="small"
          label={`${menu.name} *`}
          value={button.idRelated}
          select
          onChange={(e) => {
            const value = e.target.value
            const callback = (value: any, menus: any) => onChange(e.target.name, value, button.id, id, menus)

            if (button.type === 3 && value === 'new') {
              handleAddChildren(id, callback)
            } else callback(value, menus)
          }}
        >
          {menu.options
            .filter((item: any) => item.value !== id)
            .map((item: any) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
    )
  }

  if (tree.length > 0)
    return tree.map((c: any) => (
      <div key={c.id}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            {c.name}
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <Grid
                container
                columnSpacing={2}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: '100%',
                  marginBottom: '45px',
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: 0,
                    margin: 0,
                    width: '100%',
                    marginBottom: '30px',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      variant="standard"
                      name="title"
                      size="small"
                      label="Título do menu *"
                      value={c.name}
                      onChange={(e) => {
                        onChangeMenu(e.target.name, e.target.value, c.id, menus)
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  &nbsp;
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                  {c.children.length === 0 && (
                    <Button variant="outlined" color="error" onClick={() => handleRemoveMenu(c.id)} size="small">
                      Remover menu
                    </Button>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Mensagem *"
                      name="content"
                      multiline
                      rows={5}
                      value={c.content}
                      variant="standard"
                      size="small"
                      inputProps={{ maxLength: 3000 }}
                      onChange={(e) => {
                        onChangeMenu(e.target.name, e.target.value, c.id, menus)
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {c.buttons.length > 2 && c.buttons.length <= 3 ? (
                    <FormControl fullWidth>
                      <TextField
                        label="Cabeçalho"
                        name="header"
                        value={c.header}
                        variant="standard"
                        size="small"
                        inputProps={{ maxLength: 20 }}
                        onChange={(e) => {
                          onChangeMenu(e.target.name, e.target.value, c.id, menus)
                        }}
                      />
                    </FormControl>
                  ) : (
                    <Alert severity="warning">
                      A quantidade atual de botões do menu não permite a utilização de cabeçalho e rodapé na mensagem.
                    </Alert>
                  )}
                  {c.buttons.length > 2 && c.buttons.length <= 3 && (
                    <FormControl fullWidth>
                      <TextField
                        label="Rodapé"
                        name="footer"
                        multiline
                        rows={3}
                        value={c.footer}
                        variant="standard"
                        size="small"
                        inputProps={{ maxLength: 60 }}
                        onChange={(e) => {
                          onChangeMenu(e.target.name, e.target.value, c.id, menus)
                        }}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
              <DragDropContext
                onDragEnd={(e: any) => {
                  if (!e.destination) return
                  let tempData = Array.from(c.buttons)
                  let [source_data] = tempData.splice(e.source.index, 1)
                  tempData.splice(e.destination.index, 0, source_data)
                  handleDragButtonsEnd(c.id, tempData)
                }}
              >
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <div ref={provider.innerRef} {...provider.droppableProps}>
                      {c.buttons.map((button: any, index: number) => (
                        <Draggable key={button.id} draggableId={`button-${button.id}`} index={index}>
                          {(provider) => (
                            <Grid
                              key={button.id}
                              container
                              columnSpacing={2}
                              sx={{
                                padding: '0 0 0 20px',
                                margin: 0,
                                width: '100%',
                                marginBottom: '20px',
                              }}
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                            >
                              <Grid
                                {...provider.dragHandleProps}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                <DragIndicatorIcon />
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth size="small">
                                  <TextField
                                    variant="standard"
                                    name="name"
                                    size="small"
                                    label="Label *"
                                    value={button.name}
                                    inputProps={{ maxLength: 25 }}
                                    onChange={(e) => {
                                      onChange(e.target.name, e.target.value, button.id, c.id, menus)
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                <FormControl fullWidth size="small">
                                  <TextField
                                    variant="standard"
                                    name="type"
                                    size="small"
                                    label="Tipo *"
                                    value={button.type}
                                    select
                                    onChange={(e) => {
                                      onChange(e.target.name, e.target.value, button.id, c.id, menus)
                                    }}
                                  >
                                    {buttonOptions.map((item: any) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                {getSelectIdRelatedComponent(button, c.id)}
                              </Grid>
                              <Grid item>
                                <Fab onClick={() => handleRemoveButton(c.id, button.id)} size="small" color="error">
                                  <DeleteIcon sx={{ fontSize: 14 }} />
                                </Fab>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </FormGroup>
          </AccordionDetails>
          <AccordionActions>
            <Button variant="outlined" onClick={() => handleAddNewButton(c.id)} size="small">
              Adicionar Botão
            </Button>
          </AccordionActions>
        </Accordion>

        <div style={{ paddingTop: '15px', paddingLeft: '30px' }}>
          <ChildrenComponent
            users={users}
            departments={departments}
            tree={c.children}
            menus={menus}
            handleAddNewButton={handleAddNewButton}
            handleAddChildren={handleAddChildren}
            onChange={onChange}
            onChangeMenu={onChangeMenu}
            handleRemoveButton={handleRemoveButton}
            handleRemoveMenu={handleRemoveMenu}
            handleDragButtonsEnd={handleDragButtonsEnd}
          />
        </div>
      </div>
    ))
}

export default Ura
