import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CircleIcon from '@mui/icons-material/Circle'

import Logo from '../Logo'
import MenuLeft from './MenuLeft'
import { Badge, Menu } from '@mui/material'
import { request } from '../../utils/fetcher'
import { FilterContext } from '../../context/filter'
import DeviceModal from './DeviceModal'

const drawerWidth = 150

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `50px`,
  [theme.breakpoints.up('sm')]: {
    width: `50px`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const Layout: React.FC<{ children?: any }> = ({ children }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [deviceModal, setDeviceModal] = React.useState<any>(null)
  const [open, setOpen] = React.useState(false)
  const [openDevicesList, setOpenDevicesList] = React.useState<null | HTMLElement>(null)
  const [devices, setDevices] = React.useState([])
  const { handleLogout } = React.useContext(FilterContext)

  React.useEffect(() => {
    loadAllDevices()
    setInterval(() => loadAllDevices(), 10000)
  }, [])

  const loadAllDevices = async () => {
    const data = await request(`company/get-devices`, {}, {}, { method: 'GET' })
    setDevices(data.devices)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logout = () => {
    navigate('/login')
    handleLogout()
    localStorage.removeItem('x-auth-chat')
  }

  const handleOpenDevicesList = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenDevicesList(e.currentTarget)
  }

  const handleCloseDevicesList = () => {
    setOpenDevicesList(null)
  }

  const offlineDevices = devices.filter((d: any) => d.status === 1).length > 0

  return (
    <Box sx={{ display: 'flex' }}>
      <DeviceModal device={deviceModal} handleClose={() => setDeviceModal(null)} />

      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ height: '40px', minHeight: '40px' }}>
        <Toolbar style={{ paddingLeft: 18, height: '40px', minHeight: '40px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2.5,
              '& svg': { fontSize: 20 },
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span className="mobile-hide" style={{ marginRight: '15px' }}>
                <Logo />
              </span>
            </Box>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <Button
                  id="devices-button"
                  aria-controls={Boolean(openDevicesList) ? 'devices-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(openDevicesList) ? 'true' : undefined}
                  onClick={handleOpenDevicesList}
                  variant={offlineDevices ? 'contained' : 'outlined'}
                  color={offlineDevices ? 'error' : 'primary'}
                  style={{
                    color: '#fff',
                    fontSize: 12,
                    textTransform: 'inherit',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                  }}
                  endIcon={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <PhoneIphoneIcon style={{ fontSize: 18 }} />
                      <KeyboardArrowDownIcon style={{ fontSize: 12 }} />
                    </div>
                  }
                >
                  <span className="mobile-hide">Telefones</span>
                </Button>
                <Menu
                  id="devices-menu"
                  anchorEl={openDevicesList}
                  open={Boolean(openDevicesList)}
                  onClose={handleCloseDevicesList}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <div style={{ padding: '10px 20px' }}>
                    {devices.map((device: any) => {
                      const { name, status } = device
                      const statusColor = status === 0 ? 'green' : status === 1 ? '#ccc' : 'red'
                      const statusLabel = status === 0 ? 'on-line' : status === 1 ? 'off-line' : 'desativado'
                      return (
                        <div
                          onClick={() => setDeviceModal(device)}
                          style={{
                            cursor: 'pointer',
                            padding: '10px 0',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CircleIcon sx={{ fontSize: 12, color: statusColor }} /> &nbsp; {name} ({statusLabel})
                        </div>
                      )
                    })}
                  </div>
                </Menu>
              </div>

              <Button onClick={() => navigate('/profile')} style={{ color: '#fff' }}>
                <ManageAccountsIcon style={{ fontSize: 22 }} />
              </Button>

              <Button onClick={logout} style={{ fontSize: 12, textTransform: 'inherit', color: '#fff' }}>
                <span className="mobile-hide">&nbsp; Sair &nbsp;</span> <LogoutIcon style={{ fontSize: 14 }} />
              </Button>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            height: '40px',
            minHeight: '40px',
            backgroundColor: 'rgb(101, 31, 255)',
            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px -1px',
          }}
        >
          <IconButton onClick={handleDrawerClose} sx={{ '& svg': { fontSize: 16, color: '#fff' } }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <MenuLeft open={open} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, pt: 3, height: 'calc(100dvh)', width: 'calc(100% - 50px)' }}>
        <div style={{ minHeight: '17px', display: 'block', height: '17px' }} />

        {children}
      </Box>
    </Box>
  )
}

export default Layout
