import { FC, useEffect, useState } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'

import Fab from '@mui/material/Fab'
import MicIcon from '@mui/icons-material/Mic'
import PauseIcon from '@mui/icons-material/Pause'
import StopIcon from '@mui/icons-material/Stop'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import SendIcon from '@mui/icons-material/Send'

const AudioRecord: FC<{
  handleAttach: any
  handleSend?: any
  record?: boolean
  onlyAudio?: boolean
}> = ({ handleAttach, handleSend, record, onlyAudio = false }) => {
  const [audio, setAudio] = useState<any>(null)

  const { startRecording, stopRecording, togglePauseResume, recordingBlob, isRecording, isPaused, recordingTime } =
    useAudioRecorder()

  useEffect(() => {
    if (record) handleStart()
  }, [record])

  useEffect(() => {
    if (recordingBlob) setAudio(recordingBlob)
  }, [recordingBlob])

  const handleStart = () => {
    startRecording()
  }

  const handleStop = () => {
    stopRecording()
  }

  const handleTogglePauseResume = () => {
    togglePauseResume()
  }

  const showRecordingTime = () => {
    const minutes = Math.floor(recordingTime / 60)
    const seconds = recordingTime - minutes * 60

    const str_pad_left = (string: any, pad: any, length: any) => {
      return (new Array(length + 1).join(pad) + string).slice(-length)
    }

    return str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2)
  }

  const clearAudio = () => {
    stopRecording()
    setAudio(null)
  }

  const attach = () => {
    handleAttach(audio)
    setAudio(null)
  }

  return (
    <div style={{ height: '60px', minWidth: '150px', display: 'flex', alignItems: 'center' }}>
      {!audio && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: isRecording || audio ? '300px' : 'auto',
          }}
        >
          <div>
            <Fab
              onClick={() => {
                isRecording || isPaused ? handleTogglePauseResume() : handleStart()
              }}
              color={isRecording || isPaused ? 'error' : 'primary'}
              size="small"
            >
              {!isRecording && !isPaused && <MicIcon sx={{ fontSize: 22 }} />}
              {isRecording && !isPaused && <PauseIcon sx={{ fontSize: 22 }} />}
              {isRecording && isPaused && <MicIcon sx={{ fontSize: 22 }} />}
            </Fab>
          </div>

          <div>{isRecording ? showRecordingTime() : ''}</div>

          <div>
            {isRecording && (
              <Fab
                onClick={() => {
                  handleStop()
                }}
                color={'primary'}
                size="small"
              >
                <StopIcon sx={{ fontSize: 22 }} />
              </Fab>
            )}
          </div>
        </div>
      )}

      {audio && !isRecording && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Fab
            onClick={() => {
              clearAudio()
            }}
            color={'error'}
            size="small"
          >
            <DeleteIcon sx={{ fontSize: 18 }} />
          </Fab>

          <audio autoPlay={false} controls>
            <source src={URL.createObjectURL(audio)} />
          </audio>

          {onlyAudio ? (
            <Fab
              onClick={() => {
                attach()
                handleSend && handleSend()
              }}
              size="small"
              color="primary"
            >
              <SendIcon sx={{ fontSize: 12 }} />
            </Fab>
          ) : (
            <Fab
              onClick={() => {
                attach()
              }}
              color={'success'}
              size="small"
            >
              <AddIcon sx={{ fontSize: 18 }} />
            </Fab>
          )}
        </div>
      )}
    </div>
  )
}

export default AudioRecord
