import theme from '../../styles/themes'

import Layout from '../../components/Layout'
import { Box } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'

import Chart from './Chart'

const Graphs = () => {
  const chartsList = [
    { title: '', type: 'generalData' },
    { title: 'Mensagens Recebidas', type: 'messagesHeatMap' },
    { title: 'Clientes Em Atendimento x Finalizados', type: 'chatsActiveVsInactive' },
    { title: 'Tempo Médio de Duração de Chat', type: 'chatDurationAverageTime' },
  ]

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <Layout>
        <Box
          sx={{
            maxWidth: '100%',
            margin: '20px',
          }}
        >
          <h4
            style={{
              padding: '0 20px',
              margin: '0 0 20px 0',
              fontSize: 16,
              fontWeight: '400',
              color: theme.colors.primary,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <BarChartIcon /> Gráficos
          </h4>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              padding: '0 20px 100px 20px',
              gap: '20px',
            }}
          >
            {chartsList.map((chart, index) => (
              <Chart key={index} type={chart.type} title={chart.title} filters={[]} />
            ))}
          </div>
        </Box>
      </Layout>
    </div>
  )
}

export default Graphs
