import theme from '../../styles/themes'
import { CircularProgress, Paper, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { request } from '../../utils/fetcher'
import { BarChart, PieChart } from '@mui/x-charts'
import ForumIcon from '@mui/icons-material/Forum'
import PersonIcon from '@mui/icons-material/Person'

const Chart: FC<{ title?: string; type: string; filters: any }> = ({ title, type, filters }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    getChartData(type, filters)
  }, [])

  const getChartData = async (chart: string, filters: any) => {
    setLoading(true)
    const resp = await request('company/graphs', { type: chart, filters })
    setLoading(false)
    if (resp.success && resp.chart !== null) setData(resp.chart.data)
  }

  const monthFormatter = (value: string) => {
    switch (value) {
      case '01':
        return 'Jan'
      case '02':
        return 'Fev'
      case '03':
        return 'Mar'
      case '04':
        return 'Abr'
      case '05':
        return 'Mai'
      case '06':
        return 'Jun'
      case '07':
        return 'Jul'
      case '08':
        return 'Ago'
      case '09':
        return 'Set'
      case '10':
        return 'Out'
      case '11':
        return 'Nov'
      case '12':
        return 'Dez'
      default:
        return ''
    }
  }

  const getChartComponent = (title: string | undefined, type: string, data: any) => {
    switch (type) {
      case 'chatsActiveVsInactive':
        return (
          <Paper sx={{ padding: '20px 10px', flex: 1 }}>
            {title && <Typography variant="h6">{title}</Typography>}
            {/* <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', gap: '16px' }}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#0288d1', display: 'block', width: '8px', height: '16px' }}></div>
                <div style={{ backgroundColor: '#0288d1', display: 'block', width: '8px', height: '16px' }}></div>
                &nbsp;&nbsp; <span>Aberto</span>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#d32f2f', display: 'block', width: '8px', height: '16px' }}></div>
                <div style={{ backgroundColor: '#d32f2f', display: 'block', width: '8px', height: '16px' }}></div>
                &nbsp;&nbsp; <span>Fechado</span>
              </div>
            </div> */}
            <BarChart
              dataset={Object.keys(data).map((key) => data[key])}
              series={[
                {
                  color: '#0288d1',
                  label: 'Iniciado pela Empresa (Em Atendimento)',
                  dataKey: 'NewCompanyChats_Active',
                  stack: 'active',
                },
                {
                  color: '#0288d1',
                  label: 'Iniciado pelo Cliente (Em Atendimento)',
                  dataKey: 'NewClientChats_Active',
                  stack: 'active',
                },
                {
                  color: '#d32f2f',
                  label: 'Iniciado pelo Cliente (Finalizado)',
                  dataKey: 'NewClientChats_Closed',
                  stack: 'closed',
                },
                {
                  color: '#d32f2f',
                  label: 'Iniciado pela Empresa (Finalizado)',
                  dataKey: 'NewCompanyChats_Closed',
                  stack: 'closed',
                },
              ]}
              xAxis={[{ scaleType: 'band', dataKey: 'month', valueFormatter: monthFormatter }]}
              slotProps={{ legend: { hidden: true } }}
              height={250}
            />
          </Paper>
        )
      case 'messagesHeatMap':
        const maxValue = Math.max(...data.map((obj: any) => obj.countAllMessages))

        const getColor = (value: number) => {
          if (value === 0) return '#f8f8f8'
          const intensity = Math.round((value / maxValue) * 255)
          return `rgb(${255 - intensity}, ${255 - intensity}, 255)`
        }

        const grid = Array.from({ length: 7 }, () => Array.from({ length: 24 }, () => 0))
        data.forEach(
          ({ weekDay, hourDay, countAllMessages }: { weekDay: string; hourDay: string; countAllMessages: number }) => {
            grid[parseInt(weekDay)][parseInt(hourDay)] = countAllMessages
          }
        )

        const getWeekDayLabel = (day: number) => {
          switch (day) {
            case 0:
              return 'Dom'
            case 1:
              return 'Seg'
            case 2:
              return 'Ter'
            case 3:
              return 'Qua'
            case 4:
              return 'Qui'
            case 5:
              return 'Sex'
            case 6:
              return 'Sab'
            default:
              return ''
          }
        }

        return (
          <>
            {title && <Typography variant="h6">{title}</Typography>}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                width: '100%',
                margin: '15px 0 40px 0',
              }}
            >
              {grid.map((day, weekIndex) => (
                <div key={`day-${weekIndex}`} style={{ display: 'flex', gap: '2px' }}>
                  <div
                    style={{ width: '30px', fontSize: '11px', height: '25px', display: 'flex', alignItems: 'center' }}
                  >
                    {getWeekDayLabel(weekIndex)}
                  </div>

                  {day.map((count, hourIndex) => (
                    <div
                      key={`hour-${hourIndex}`}
                      style={{
                        backgroundColor: getColor(count),
                        width: 'calc(100%/24)',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '11px',
                        color: '#000',
                        borderRadius: '4px',
                      }}
                      title={`${getWeekDayLabel(weekIndex)} as ${hourIndex}h: ${count} mensagens`}
                    >
                      {count > 0 ? count : ''}
                    </div>
                  ))}
                </div>
              ))}
              <div style={{ display: 'flex', alignItems: 'center', gap: '2px', marginLeft: '30px' }}>
                {[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ].map((hour: string) => (
                  <div
                    key={`hour-${hour}`}
                    style={{
                      width: 'calc(100%/24)',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '11px',
                    }}
                  >
                    {hour}h
                  </div>
                ))}
              </div>
            </div>
          </>
        )
      case 'chatDurationAverageTime':
        // const colors: { [key: string]: string } = {
        //   '1 hour': '#FF7043',
        //   '12 hours': '#FF7043',
        //   '24 hours': '#FF7043',
        //   '72 hours': '#FF7043',
        //   '7 days': '#FF7043',
        //   '15 days': '#FF7043',
        //   'More than 15 days': '#FF7043',
        // }
        // return (
        //   <Paper sx={{ padding: '20px 10px', width: '45%' }}>
        //     {title && <Typography variant="h6">{title}</Typography>}
        //     <PieChart
        //       height={300}
        //       series={[
        //         {
        //           data: Object.keys(data).map((key) => {
        //             return {
        //               color: colors[data[key].label],
        //               ...data[key],
        //             }
        //           }),
        //           arcLabel: (params) => `${params.value}`,
        //         },
        //       ]}
        //     />
        //   </Paper>
        // )

        return (
          <Paper sx={{ padding: '20px 10px', flex: 1 }}>
            {title && <Typography variant="h6">{title}</Typography>}
            <BarChart
              dataset={Object.keys(data).map((key) => data[key])}
              series={[
                {
                  color: '#651FFF',
                  label: 'Chats',
                  dataKey: 'value',
                },
              ]}
              xAxis={[{ scaleType: 'band', dataKey: 'label' }]}
              slotProps={{ legend: { hidden: true } }}
              height={250}
            />
          </Paper>
        )
      case 'generalData':
        return (
          <div style={{ width: '100%', display: 'flex', gap: '100px', padding: '30px 0', marginBottom: '20px' }}>
            <div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <span title="Clientes">
                  <PersonIcon sx={{ fontSize: 64 }} color="primary" />
                </span>
                <div style={{ paddingRight: '20px', borderRight: `1px solid ${theme.colors.primary}` }}>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    ABERTOS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.NewActiveChats_Total}
                  </Typography>
                </div>
                <div>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    FECHADOS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.NewClosedChats_Total}
                  </Typography>
                </div>
              </div>
            </div>

            <div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <span title="Mensagens">
                  <ForumIcon sx={{ fontSize: 64 }} color="primary" />
                </span>
                <div style={{ paddingRight: '20px', borderRight: `1px solid ${theme.colors.primary}` }}>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    ENVIADAS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.SentMessages}
                  </Typography>
                </div>
                <div>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    RECEBIDAS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.ReceivedMessages}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const getLoaderComponent = (type: string) => {
    switch (type) {
      case 'generalData':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '100%',
              minHeight: 150,
            }}
          >
            <CircularProgress />
          </div>
        )
      case 'messagesHeatMap':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '100%',
              minHeight: 150,
            }}
          >
            <CircularProgress />
          </div>
        )
      default:
        return (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 500, minHeight: 300 }}
          >
            <CircularProgress />
          </div>
        )
    }
  }

  return (
    <>
      {loading && getLoaderComponent(type)}
      {data && getChartComponent(title, type, data)}
    </>
  )
}

export default Chart
